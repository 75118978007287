import React from 'react';

const AutomateRod = ({
  formId, onChange, value,
}) => (
  <div className="form-group">
    <label htmlFor={`automateRod__${formId}`}>Płaskownik do montazu automatu</label>
    <select
      className="form-control"
      id={`automateRod__${formId}`}
      name="automateRod"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value="yes">TAK</option>
      <option value="no">NIE</option>
    </select>
  </div>
);

export default AutomateRod;
