import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx/xlsx.mjs';
import axios from 'axios';
import {
  faFilePdf, faPrint, faSpinner, faWindowMaximize, faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from 'react-dom/server';
import printJS from 'print-js';
import { useSelector } from 'react-redux';
import { KNS_MAILBOX_YES } from 'components/Input/KnsMailbox';
import {
  ADDITIONAL_HORIZONTAL_FRAME_AMOUNT, ADDITIONAL_HORIZONTAL_FRAME_MATERIAL, ADDITIONAL_HORIZONTAL_FRAME_SIZE, ADDITIONAL_VERTICAL_FRAME_AMOUNT, ADDITIONAL_VERTICAL_FRAME_MATERIAL, ADDITIONAL_VERTICAL_FRAME_SIZE, ADDITIONAL_VERTICAL_MODULE_AMOUNT, ADDITIONAL_VERTICAL_MODULE_SIZE, BASE_BRACKET_AMOUNT, BASE_BRACKET_SIZE, COVERING_BRACKET_AMOUNT, COVERING_BRACKET_SIZE, FILLING_100_20_AMOUNT, FILLING_100_20_MATERIAL, FILLING_100_20_SIZE, FILLING_20_20_AMOUNT, FILLING_20_20_MATERIAL, FILLING_20_20_SIZE, FILLING_40_20_AMOUNT, FILLING_40_20_MATERIAL, FILLING_40_20_SIZE, FILLING_80_20_AMOUNT, FILLING_80_20_MATERIAL, FILLING_80_20_SIZE, FILLING_AMOUNT, FILLING_FORTIFICATION_AMOUNT, FILLING_FORTIFICATION_MATERIAL, FILLING_FORTIFICATION_SIZE, FILLING_LONG_AMOUNT, FILLING_LONG_EXISTING, FILLING_LONG_MATERIAL, FILLING_LONG_SIZE, FILLING_SIZE, FRAME_WIDTH_HORIZONTAL_AMOUNT, FRAME_WIDTH_HORIZONTAL_SIZE, MODULE_WIDTH_AMOUNT, MODULE_WIDTH_SIZE, NOT_APPLICABLE, PLATE_AMOUNT, PLATE_HEIGHT, PLATE_WIDTH, VERTICAL_FRAME_AMOUNT, VERTICAL_FRAME_SIZE, VERTICAL_MODULE_AMOUNT, VERTICAL_MODULE_SIZE, VERTICAL_PART_AMOUNT, VERTICAL_PART_MATERIAL, VERTICAL_PART_SIZE,
} from 'ResponseConstants';
import PdfHtml from './PdfHtml';
import { createFormTitle } from '../OrderForm/OrderFormHeader';
import {
  FENCE_TYPE_FOLDING_GATE, FENCE_TYPE_GATE_GATEWAY, FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY, FENCE_TYPE_NON_SYMMETRICAL_GATE,
  FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE, FENCE_TYPE_SINGLE_LEAF_GATE,
  FENCE_TYPE_SPAN,
} from '../../FenceTypesConstants';
// eslint-disable-next-line import/no-cycle
import Page from './Page';
import './PdfBtn.css';
import {
  MODEL_3D_4,
  MODEL_3D_5,
  MODEL_HIGHWAY,
  MODEL_P302_PB002,
  MODEL_PB001,
  MODEL_PP002DIVERSO,
  MODEL_PP002DIVERSO1,
  MODEL_PP002DIVERSO2,
  MODEL_PP002DIVERSO3,
  MODEL_PP002P102,
  MODEL_PP002P64,
  MODEL_PP002P82,
  MODEL_PP002P82COLORE1,
  MODEL_PP002P82COLORE2,
  MODEL_PP002P82MIX,
  MODEL_PP002P82NOCE1,
  MODEL_PP002P82NOCE2,
  MODEL_PP002P82PINO1,
  MODEL_PP002P82PINO2,
  MODEL_PS001,
  MODEL_PS002,
  MODEL_PS003,
  MODEL_PS004,
  MODEL_PS005,
  MODEL_PB155,
  MODEL_2D,
  MODEL_2DS,
  MODEL_VERTICALE,
  MODEL_STILE,
  MODEL_VMS,
  MODEL_ECO,
  MODEL_PS004_2,
  MODEL_PP002P82_0,
  MODEL_PP002P102_0,
  MODEL_PP002P82COLORE1_0,
  MODEL_PP002P82PINO1_0,
  MODEL_PP002P82COLORE2_0,
  MODEL_PP002P82PINO2_0,
  MODEL_PP002P82NOCE1_0,
  MODEL_PP002P82NOCE2_0,
  MODEL_VERTICALE_2,
  MODEL_VERTICALE_3,
  MODEL_PB003,
  MODEL_PP001P46,
  MODEL_PP001N,
  MODEL_PP002,
  MODEL_PP002P,
  MODEL_VERTICALE_LM,
  MODEL_EQ,
  MODEL_PP002P64V,
  MODEL_P302_PS005,
  MODEL_PP001C,
  MODEL_PP001CC,
  MODEL_PP001W,
  MODEL_TVORA2,
  MODEL_TVORA4,
  MODEL_TVORA5,
  MODEL_P302,
} from '../../ModelsConstants';

export const modelsSpreadOverTwoPages = (form) => {
  const gatewaysOnTwoPages = [
    MODEL_2D,
    MODEL_2DS,
    MODEL_3D_4,
    MODEL_3D_5,
    MODEL_P302,
    MODEL_P302_PB002,
    MODEL_P302_PS005,
    MODEL_PB001,
    MODEL_PB003,
    MODEL_PB155,
    MODEL_PP001C,
    MODEL_PP001CC,
    MODEL_PP001N,
    MODEL_PP001W,
    MODEL_PP001P46,
    MODEL_PP002P64,
    MODEL_PP002P82,
    MODEL_PP002P82COLORE1,
    MODEL_PP002P82COLORE1_0,
    MODEL_PP002P82COLORE2,
    MODEL_PP002P82COLORE2_0,
    MODEL_PP002P82PINO1,
    MODEL_PP002P82PINO1_0,
    MODEL_PP002P82PINO2,
    MODEL_PP002P82PINO2_0,
    MODEL_PP002P82NOCE1,
    MODEL_PP002P82NOCE1_0,
    MODEL_PP002P82NOCE2,
    MODEL_PP002P82NOCE2_0,
    MODEL_PP002P82MIX,
    MODEL_PP002P82_0,
    MODEL_PP002P102,
    MODEL_PP002P102_0,
    MODEL_PP002,
    MODEL_PP002P,
    MODEL_PS001,
    MODEL_PS002,
    MODEL_PS003,
    MODEL_PS004_2,
    MODEL_TVORA2,
    MODEL_TVORA4,
    MODEL_TVORA5,
    MODEL_VERTICALE,
    MODEL_PP002DIVERSO,
    MODEL_PP002DIVERSO1,
    MODEL_PP002DIVERSO2,
    MODEL_PP002DIVERSO3,
  ];

  const modelsWithTwoPagesOverH1000 = [
    MODEL_PP002P82,
    MODEL_PP002P82_0,
    MODEL_PP002P102,
    MODEL_PP002P102_0,
    MODEL_PP002P82COLORE1,
    MODEL_PP002P82PINO1,
    MODEL_PP002P82MIX,
    MODEL_PP002P82NOCE1,
    MODEL_PP002DIVERSO,
  ];

  const modelsWithTwoPagesOverH1179 = [
    MODEL_PP002P82COLORE2,
    MODEL_PP002P82NOCE2,
    MODEL_PP002P82PINO2,
  ];

  const typesWithTwoPages = [
    FENCE_TYPE_GATE_TILTING,
    FENCE_TYPE_FOLDING_GATE,
    FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE,
    FENCE_TYPE_SINGLE_LEAF_GATE,
    FENCE_TYPE_GATE_SELF_SUPPORTING,
  ];

  const nonSymmetricalModelsWithTwoPages = [
    MODEL_PP002P82,
    MODEL_PP002P82_0,
    MODEL_PP002P102,
    MODEL_PP002P102_0,
    MODEL_PP002P82COLORE1,
    MODEL_PP002P82COLORE2,
    MODEL_PP002P82PINO1,
    MODEL_PP002P82NOCE2,
    MODEL_PP002P82PINO2,
    MODEL_PP002P82MIX,
    MODEL_PP002P82NOCE1,
    MODEL_PP002DIVERSO,
  ];

  if (form.type === FENCE_TYPE_GATEWAY && gatewaysOnTwoPages.includes(form.model)) {
    return true;
  }

  if ((form.model === MODEL_PP002DIVERSO || form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) && (form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || form.type === FENCE_TYPE_FOLDING_GATE)) {
    return true;
  }
  if ((form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
    return true;
  }
  if ((form.model === MODEL_PP002DIVERSO || form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) && (form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE)) {
    return true;
  }

  if (((form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width >= 3000) || (form.type === FENCE_TYPE_GATE_TILTING && form.values.width >= 6000)) && form.model === MODEL_PP002DIVERSO1 && form.values.height && (parseInt(form.values.height) === 935 || parseInt(form.values.height) === 1125 || parseInt(form.values.height) === 1315)) {
    return false;
  }

  if ((form.model === MODEL_PS004 || form.model === MODEL_PS005) && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
    return true;
  }

  if (form.model === MODEL_P302) {
    if (form.type === FENCE_TYPE_FOLDING_GATE) {
      return true;
    }
  }

  if (form.model === MODEL_P302_PB002) {
    if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
      return true;
    }
  }

  if ((form.model === MODEL_PP002P82 || form.model === MODEL_PP002P102) && form.type === FENCE_TYPE_GATE_TILTING && form.values.width <= 6000) {
    return false;
  }

  if ((form.model === MODEL_PP002P82_0 || form.model === MODEL_PP002P82COLORE1_0 || form.model === MODEL_PP002P82COLORE2_0 || form.model === MODEL_PP002P82PINO1_0 || form.model === MODEL_PP002P82PINO2_0 || form.model === MODEL_PP002P82NOCE1_0 || form.model === MODEL_PP002P82NOCE2_0) && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
    return true;
  }

  if ((form.model === MODEL_PP002P82COLORE1_0 || form.model === MODEL_PP002P82COLORE2_0 || form.model === MODEL_PP002P82PINO1_0 || form.model === MODEL_PP002P82PINO2_0 || form.model === MODEL_PP002P82NOCE1_0 || form.model === MODEL_PP002P82NOCE2_0 || form.model === MODEL_PP002P82MIX) && form.type === FENCE_TYPE_GATE_TILTING && form.values.width < 6000) {
    return false;
  }

  if ((form.model === MODEL_PP002P82MIX || form.model === MODEL_PP002P82COLORE1 || form.model === MODEL_PP002P82COLORE2 || form.model === MODEL_PP002P82PINO1 || form.model === MODEL_PP002P82PINO2 || form.model === MODEL_PP002P82NOCE1 || form.model === MODEL_PP002P82NOCE2 || form.model === MODEL_PP002DIVERSO || form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3 || form.model === MODEL_PP002P102) && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
    return true;
  }

  if ((form.model === MODEL_PP002P82 || form.model === MODEL_PP002DIVERSO || form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) && (form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || form.type === FENCE_TYPE_FOLDING_GATE)) {
    return false;
  }

  if (form.model === MODEL_PP002P82 && form.type === FENCE_TYPE_GATE_TILTING && form.values.width < 6000) {
    return false;
  }

  if ((form.model === MODEL_PP002P82 || form.model === MODEL_PP002P82PINO1 || form.model === MODEL_PP002P82NOCE1 || form.model === MODEL_PP002P82COLORE1) && form.type === FENCE_TYPE_SINGLE_LEAF_GATE && form.values.width < 3000) {
    return false;
  }

  if (form.model === MODEL_PP002P82 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width >= 5800) {
    return true;
  }

  if (form.model === MODEL_PP002P82 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width < 5800) {
    return false;
  }

  if (form.model === MODEL_PP002P102_0 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
    return true;
  }

  if (form.model === MODEL_PP002P102_0 && (form.type === FENCE_TYPE_GATE_TILTING || form.type === FENCE_TYPE_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_GATE || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE || form.type === FENCE_TYPE_GATE_GATEWAY)) {
    return true;
  }

  if (form.model === MODEL_PP002P82_0 && (form.type === FENCE_TYPE_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE || form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE)) {
    return true;
  }

  if (form.model === MODEL_PP002P102_0 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width <= 5800) {
    return false;
  }

  if (form.model === MODEL_PP002P82_0 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
    return true;
  }

  if (form.model === MODEL_PS001 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width <= 5800) {
    return false;
  }

  if ((form.model === MODEL_PP002P82_0) && form.type === FENCE_TYPE_SINGLE_LEAF_GATE) {
    return true;
  }

  if ((form.model === MODEL_PP002P82_0 || form.model === MODEL_PP002P102_0) && form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE) {
    return false;
  }

  if (form.model === MODEL_PP001N && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width >= 5800) {
    return true;
  }

  if (form.model === MODEL_PP001P46 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
    return true;
  }

  if (modelsWithTwoPagesOverH1000.indexOf(form.model) > -1 && typesWithTwoPages.indexOf(form.type) > -1 && form.values.height > 999) {
    return true;
  }
  if ((form.model === MODEL_PP002P82NOCE1 || form.model === MODEL_PP002P82COLORE1 || form.model === MODEL_PP002P82PINO1) && (form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE)) {
    return true;
  }
  if (nonSymmetricalModelsWithTwoPages.indexOf(form.model) > -1 && (form.type === FENCE_TYPE_GATE_GATEWAY || form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE)) {
    return true;
  }
  if (form.model === MODEL_PP002DIVERSO1 && form.values.height <= 1315) {
    return false;
  }
  if (form.model === MODEL_PS003 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING && form.values.width > 5800) {
    return true;
  }
  if ((form.model === MODEL_PB001 || form.model === MODEL_PB003) && form.values.width > 5800 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
    return true;
  }
  if ((form.model === MODEL_3D_4 || form.model === MODEL_3D_5) && form.values.width > 5800 && form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
    return true;
  }
  if ((form.model === MODEL_PP002DIVERSO1 || form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) && (form.type !== FENCE_TYPE_SPAN && form.type !== FENCE_TYPE_GATEWAY && form.type !== FENCE_TYPE_GATE_SELF_SUPPORTING)) {
    return true;
  }
  if ((form.model === MODEL_PS004 || form.model === MODEL_PS004_2) && (form.type === FENCE_TYPE_NON_SYMMETRICAL_GATE || form.type === FENCE_TYPE_GATE_GATEWAY)) {
    return true;
  }
  if ((form.model === MODEL_PS004 || form.model === MODEL_PS004_2) && (form.type === FENCE_TYPE_SINGLE_LEAF_GATE) && form.values.width >= 3000) {
    return true;
  }
  if (form.model === MODEL_PS004_2 && (form.type === FENCE_TYPE_GATE_TILTING) && form.values.width >= 6000) {
    return true;
  }
  if (form.model === MODEL_PS004_2 && (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING) && form.values.width >= 5801) {
    return true;
  }
  if (form.model === MODEL_PS004_2 && form.type === FENCE_TYPE_SPAN) {
    return true;
  }
  if (form.model === MODEL_PS004_2 && form.type === FENCE_TYPE_GATEWAY && form.values.height >= 1400 && form.values.knsMailbox === KNS_MAILBOX_YES) {
    return true;
  }
  if ((form.model === MODEL_PP002P82COLORE1 || form.model === MODEL_PP002P82NOCE1 || form.model === MODEL_PP002P82PINO1) && (form.type === FENCE_TYPE_FOLDING_GATE || form.type === FENCE_TYPE_SINGLE_LEAF_FOLDING_GATE)) {
    return true;
  }
  if (form.model === MODEL_VERTICALE_LM) {
    if (form.type === FENCE_TYPE_GATE_SELF_SUPPORTING || form.type === FENCE_TYPE_GATE_TILTING || form.type === FENCE_TYPE_GATEWAY) {
      return true;
    }
  }
  if (form.model === MODEL_PP002) {
    if (form.type === FENCE_TYPE_GATEWAY || form.type === FENCE_TYPE_GATE_TILTING) {
      return true;
    }
  }
  if (form.model === MODEL_PP002P) {
    if (form.type === FENCE_TYPE_GATE_TILTING) {
      return true;
    }
  }
  if (form.model === MODEL_EQ) {
    return true;
  }
  if (form.model === MODEL_PP002P64V) {
    if (form.type === FENCE_TYPE_GATEWAY) {
      return true;
    }
  }
  return modelsWithTwoPagesOverH1179.indexOf(form.model) > -1 && typesWithTwoPages.indexOf(form.type) > -1 && form.values.height > 1179;
};

const getFormsOnlyWithSpanTypeInChunks = (forms, size) => {
  const formsOnlyWithSpanType = [...forms].filter((f) => f.type === FENCE_TYPE_SPAN);

  const chunked = [];
  let index = 0;
  while (index < formsOnlyWithSpanType.length) {
    chunked.push(formsOnlyWithSpanType.slice(index, size + index));
    index += size;
  }

  return chunked;
};

const PdfBtn = ({ forms, orderNumber = null }) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const history = useHistory();
  let duplicate;

  const appState = useSelector((state) => state);
  const { generalComment } = appState;

  useEffect(() => {
    if (!orderNumber) return;
    const fetchOrder = async () => {
      try {
        const { data } = await axios.get(`/zamowienia/${orderNumber}/get-order-id`);
        setOrderId(data?.order?.id);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };
    fetchOrder();
  }, [orderNumber]);

  const firstFromHasNotResponse = forms[0] && Object.keys(forms[0].response).length === 0;
  if (!orderNumber || !forms.length || firstFromHasNotResponse) {
    return null;
  }

  const generatePdf = (print = false) => {
    setProcessing(true);

    const formsForPdf = forms.map((f) => f);

    const formsWithoutResponse = formsForPdf.filter((f) => !f.response || !Object.keys(f.response).length);
    const formWithoutResponse = formsWithoutResponse[0] ? formsWithoutResponse[0] : null;
    if (formWithoutResponse) {
      const title = createFormTitle(formWithoutResponse);
      const message = title ? `Dokończ formularz (${title}).` : 'Dokończ formularz.';
      setError(message);
      setProcessing(false);

      return;
    }

    const standardSignatures = () => {
      const modelsWithoutSignatures = [
        MODEL_PP002P82,
        MODEL_PP002P82PINO1,
        MODEL_PP002P82PINO2,
        MODEL_PP002P82COLORE1,
        MODEL_PP002P82COLORE2,
        MODEL_PP002P82NOCE1,
        MODEL_PP002P82NOCE2,
        MODEL_PP002P82MIX,
        MODEL_PP002DIVERSO,
        MODEL_PP002DIVERSO1,
        MODEL_PP002DIVERSO2,
        MODEL_PP002DIVERSO3,
        MODEL_PP002P102,
        MODEL_PS001,
        MODEL_PS002,
        MODEL_PS003,
        MODEL_HIGHWAY,
        MODEL_EQ,
      ];

      if (formsForPdf.every((form) => form.model === MODEL_HIGHWAY || form.model === MODEL_EQ)) {
        return false;
      }

      if (formsForPdf.every((form) => form.model === MODEL_PB155 && form.type === FENCE_TYPE_SPAN)) {
        return false;
      }

      return !(formsForPdf.every((form) => modelsWithoutSignatures.indexOf(form.model) > -1 && form.type === FENCE_TYPE_SPAN));
    };

    const nonStandardSignature = () => {
      const modelsWithoutSignatures = [
        MODEL_PP002P82MIX,
        MODEL_PP002P102,
        MODEL_PP002DIVERSO,
        MODEL_PP002DIVERSO1,
        MODEL_PP002DIVERSO2,
        MODEL_PP002DIVERSO3,
        MODEL_EQ,
      ];

      if (formsForPdf.every((form) => form.model === MODEL_EQ)) {
        return false;
      }

      if (formsForPdf.every((form) => (form.model === MODEL_PS001 || form.model === MODEL_PS002 || form.model === MODEL_PS003) && form.type === FENCE_TYPE_SPAN)) {
        return true;
      }

      if (formsForPdf.every((form) => form.model === MODEL_HIGHWAY)) {
        return true;
      }

      if (formsForPdf.every((form) => form.model === MODEL_HIGHWAY)) {
        return true;
      }

      if (formsForPdf.every((form) => form.model === MODEL_PB155 && form.type === FENCE_TYPE_SPAN)) {
        return true;
      }

      if (standardSignatures() === false) {
        return !!(formsForPdf.some((form) => modelsWithoutSignatures.indexOf(form.model) > -1 && form.type === FENCE_TYPE_SPAN));
      }
      return false;
    };

    const millSignature = () => !!formsForPdf.some((form) => form.model === MODEL_PP002P64);

    const kazimierzSignature = () => !!formsForPdf.some((form) => form.model === MODEL_PB155);

    const storageSignature = () => !!formsForPdf.some((form) => form.model === MODEL_VERTICALE_3 || form.model === MODEL_VERTICALE_2 || form.model === MODEL_EQ);

    // const storageRemovalSignature = () => !!formsForPdf.some((form) => form.model === MODEL_PP002 && form.type === FENCE_TYPE_GATEWAY);

    const plankSignature = () => {
      const modelsWithPlank = [
        MODEL_PP002P82,
        MODEL_PP002P82PINO1,
        MODEL_PP002P82PINO2,
        MODEL_PP002P82COLORE1,
        MODEL_PP002P82COLORE2,
        MODEL_PP002P82NOCE1,
        MODEL_PP002P82NOCE2,
        MODEL_PP002P82MIX,
        MODEL_PP002DIVERSO,
        MODEL_PP002DIVERSO1,
        MODEL_PP002DIVERSO2,
        MODEL_PP002DIVERSO3,
        MODEL_PP002P102,
      ];

      if (formsForPdf.every((form) => form.model === MODEL_EQ)) {
        return false;
      }

      return !!formsForPdf.some((form) => modelsWithPlank.indexOf(form.model) > -1);
    };

    const studdingSignature = () => (formsForPdf.some((form) => (form.model === MODEL_PS004 || form.model === MODEL_PS004_2) && form.type === FENCE_TYPE_SPAN));

    const railingSignature = () => {
      const modelsWithRailing = [
        MODEL_PS001,
        MODEL_PS002,
        MODEL_PS003,
        MODEL_PS004,
        MODEL_PS004_2,
      ];

      return !!formsForPdf.some((form) => modelsWithRailing.indexOf(form.model) > -1);
    };

    const weldingSignature = () => {
      const spanModelsWithoutWelding = [
        MODEL_PS001,
        MODEL_PS002,
        MODEL_PS003,
        MODEL_PS004,
        MODEL_PB155,
      ];

      if (formsForPdf.every((form) => form.model === MODEL_EQ)) {
        return false;
      }

      return !!formsForPdf.some((form) => ((spanModelsWithoutWelding.indexOf(form.model) === -1) || (spanModelsWithoutWelding.indexOf(form.model) > -1 && form.type !== FENCE_TYPE_SPAN) || (spanModelsWithoutWelding.indexOf(form.model) > -1 && form.type === FENCE_TYPE_SPAN && form.values.height > 1999)));
    };

    const anglesSignature = () => {
      const modelsWithoutAngles = [
        MODEL_3D_4,
        MODEL_3D_5,
        MODEL_2D,
        MODEL_2DS,
        MODEL_PS001,
        MODEL_PS002,
        MODEL_PS003,
        MODEL_VERTICALE,
        MODEL_STILE,
      ];

      const typesWithoutAngles = [
        FENCE_TYPE_GATEWAY,
        FENCE_TYPE_SPAN,
      ];

      if (formsForPdf.every((form) => form.model === MODEL_HIGHWAY || form.model === MODEL_VMS || form.model === MODEL_ECO)) {
        return false;
      }

      if (formsForPdf.every((form) => form.type === FENCE_TYPE_GATE_SELF_SUPPORTING)) {
        return false;
      }

      if (formsForPdf.some((form) => form.type === FENCE_TYPE_GATEWAY && (form.model === MODEL_PS001 || form.model === MODEL_PS002 || form.model === MODEL_PS003))) {
        return true;
      }

      if (formsForPdf.every((form) => typesWithoutAngles.indexOf(form.type) >= 0 && modelsWithoutAngles.indexOf(form.model) >= 0)) {
        return false;
      }

      if (formsForPdf.some((form) => typesWithoutAngles.indexOf(form.type) < 0 || modelsWithoutAngles.indexOf(form.model) < 0)) {
        return true;
      }

      if (formsForPdf.some((form) => typesWithoutAngles.indexOf(form.type) < 0 && modelsWithoutAngles.indexOf(form.model) < 0)) {
        return true;
      }

      return true;
    };

    const formsCheckedForTwoPages = [];

    formsForPdf.forEach((form) => {
      formsCheckedForTwoPages.push(form);
      if (modelsSpreadOverTwoPages(form) === true && !form.copiedItem) {
        const duplicatedItem = {
          ...form,
          copiedItem: true,
        };
        formsCheckedForTwoPages.push(duplicatedItem);
      }
    });

    const formsWithoutSpanType = [...formsCheckedForTwoPages].filter((f) => f.type !== FENCE_TYPE_SPAN).map((f) => [f]);
    const formsOnlyWithSpanTypeInChunks = getFormsOnlyWithSpanTypeInChunks(formsCheckedForTwoPages, 1);

    const formsChunked = [...formsWithoutSpanType, ...formsOnlyWithSpanTypeInChunks];

    const pageNumberTotal = formsChunked.length;

    // eslint-disable-next-line array-callback-return
    const pagesArr = formsChunked.map((formsPerPage, index) => {
      duplicate = index > 0 && formsChunked[index][0].id === formsChunked[index - 1][0].id;

      return (
        ReactDOMServer.renderToStaticMarkup(<Page
          formsPerPage={formsPerPage}
          orderNumber={orderNumber}
          pageNumber={index + 1}
          pageNumberTotal={pageNumberTotal}
          generalComment={generalComment}
          isDuplicate={duplicate}
          standardSignatures={standardSignatures()}
          nonStandardSignature={nonStandardSignature()}
          millSignature={millSignature()}
          plankSignature={plankSignature()}
          studdingSignature={studdingSignature()}
          weldingSignature={weldingSignature()}
          kazimierzSignature={kazimierzSignature()}
          anglesSignature={anglesSignature()}
          railingSignature={railingSignature()}
          storageSignature={storageSignature()}
        />));
    });
    const html = PdfHtml(pagesArr);

    // window.open().document.write(html);

    fetch('https://www.api.pdf.konsport.com', {

      method: 'POST',
      headers: {
        'Content-Type': 'text/html; charset=utf-8',
        'X-Project-Name': 'SAWS',
        'X-Pdf-Name': 'karta-materialowa',
        'X-Include-Footer': '0',
        'X-Include-Page-Number': '0',
      },
      body: html,
    }).then((response) => response.json()).then((response) => {
      if (print) {
        printJS(response.url);
      } else {
        window.open(response.url);
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    })
      .finally(() => setProcessing(false));
  };

  const generateXls = () => {
    setProcessing(true);

    const formsForXls = forms.map((f) => f);

    const formsWithoutResponse = formsForXls.filter((f) => !f.response || !Object.keys(f.response).length);
    const formWithoutResponse = formsWithoutResponse[0] ? formsWithoutResponse[0] : null;
    if (formWithoutResponse) {
      const title = createFormTitle(formWithoutResponse);
      const message = title ? `Dokończ formularz (${title}).` : 'Dokończ formularz.';
      setError(message);
      setProcessing(false);

      return;
    }

    const materialsArray = [
      ['Materiał', 'Ilość [szt]', 'Wymiar [mm]'],
    ];

    formsForXls.forEach((form) => {
      const formResponseMaterials = form.response.productionMaterials;
      if (formResponseMaterials) {
        Object.entries(formResponseMaterials).forEach((element) => {
          if (element[0] === 'saw' || element[0] === 'angles' || element[0] === 'bending' || element[0] === 'gallantry' || element[0] === 'gallantry1' || element[0] === 'gallantry2' || element[0] === 'laser' || element[0] === 'studding' || element[0] === 'railing' || element[0] === 'plank') {
            if (!element[1]) {
              return null;
            }
            const elementParts = element[1];
            Object.entries(elementParts).forEach((material) => {
              if (material[0].includes('AfterBending')) {
                return null;
              }
              const materialValues = material[1];
              if (materialValues.size && materialValues.material !== 'NIE DOTYCZY') {
                const materialMaterial = form.color === 'colorHdg' ? `${String(materialValues.material)} czarne` : materialValues.material;
                const materialSizeValue = !String(materialValues.size.value).includes('x') ? materialValues.size.value : parseInt(materialValues.size.value.split('x')[0]) * parseInt(materialValues.size.value.split('x')[1]);
                materialsArray.push([materialMaterial, materialValues.amount.value, materialSizeValue]);
              }
              return null;
            });
          } if (element[0] === 'gearRail') {
            if (form.response.productionMaterials.gearRail?.gearRailAmount) {
              materialsArray.push(['Listwa zębata 8x30x1005', form.response.productionMaterials.gearRail?.gearRailAmount, 1005]);
            }
          } if (element[0] === 'calculation') {
            const elementParts = element[1];
            Object.entries(elementParts).forEach((material) => {
              const materialValues = material[1];
              if (material[0] === 'non_standard_plate') {
                materialValues.name = 'Płyta XPS niestandardowa';
              }
              const materialSizeValue = !String(materialValues.size.value).includes('x') ? materialValues.size.value : parseInt(materialValues.size.value.split('x')[0]) * parseInt(materialValues.size.value.split('x')[1]);
              materialsArray.push([materialValues.name, materialValues.amount.value, materialSizeValue]);
            });
          }
          return null;
        });
      } else {
        const addColorToMaterial = (material) => {
          if (form.color === 'colorHdg') {
            const newMaterial = `${material} czarne`;
            return newMaterial;
          }
          return material;
        };
        if (form.model === MODEL_PB001) {
          if (form.response[VERTICAL_PART_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[VERTICAL_PART_MATERIAL], form.response[VERTICAL_PART_AMOUNT], form.response[VERTICAL_PART_SIZE]]);
          }
          materialsArray.push(['Blacha PB', form.response[PLATE_AMOUNT], form.response[PLATE_HEIGHT] * form.response[PLATE_WIDTH]]);
        } if (form.model === MODEL_PP001N) {
          materialsArray.push(['Kształtownik 25x25x1,2', form.response[FILLING_AMOUNT], form.response[FILLING_SIZE]]);
          materialsArray.push(['Kształtownik 40x30x1,5', form.response[FRAME_WIDTH_HORIZONTAL_AMOUNT], form.response[FRAME_WIDTH_HORIZONTAL_SIZE]]);
        } if (form.model === MODEL_PP002) {
          materialsArray.push([addColorToMaterial('Kształtownik 60x40x1,5'), form.response[FRAME_WIDTH_HORIZONTAL_AMOUNT], form.response[FRAME_WIDTH_HORIZONTAL_SIZE]]);
          materialsArray.push([addColorToMaterial('Kształtownik 60x40x1,5'), form.response[VERTICAL_FRAME_AMOUNT], form.response[VERTICAL_FRAME_SIZE]]);
          materialsArray.push([addColorToMaterial('Kształtownik 25x25x1,2'), form.response[FILLING_AMOUNT], form.response[FILLING_SIZE]]);
        } if (form.model === MODEL_PP002DIVERSO) {
          materialsArray.push(['Kątownik 30x20x3', form.response[BASE_BRACKET_AMOUNT], form.response[BASE_BRACKET_SIZE]]);
          materialsArray.push(['Kątownik 32x18x1', form.response[COVERING_BRACKET_AMOUNT], form.response[COVERING_BRACKET_SIZE]]);
          if (form.response[FILLING_20_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_20_20_MATERIAL], form.response[FILLING_20_20_AMOUNT], form.response[FILLING_20_20_SIZE]]);
          }
          if (form.response[FILLING_40_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_40_20_MATERIAL], form.response[FILLING_40_20_AMOUNT], form.response[FILLING_40_20_SIZE]]);
          }
          if (form.response[FILLING_80_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_80_20_MATERIAL], form.response[FILLING_80_20_AMOUNT], form.response[FILLING_80_20_SIZE]]);
          }
          if (form.response[FILLING_100_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_100_20_MATERIAL], form.response[FILLING_100_20_AMOUNT], form.response[FILLING_100_20_SIZE]]);
          }
          if (form.response[FILLING_LONG_EXISTING]) {
            materialsArray.push([form.response[FILLING_LONG_MATERIAL], form.response[FILLING_LONG_AMOUNT], form.response[FILLING_LONG_SIZE]]);
          }
          if (form.response[FILLING_FORTIFICATION_MATERIAL]) {
            materialsArray.push([form.response[FILLING_FORTIFICATION_MATERIAL], form.response[FILLING_FORTIFICATION_AMOUNT], form.response[FILLING_FORTIFICATION_SIZE]]);
          }
        } if (form.model === MODEL_PP002DIVERSO1) {
          materialsArray.push(['Kątownik 30x20x3', form.response[BASE_BRACKET_AMOUNT], form.response[BASE_BRACKET_SIZE]]);
          materialsArray.push(['Kątownik 32x18x1', form.response[COVERING_BRACKET_AMOUNT], form.response[COVERING_BRACKET_SIZE]]);
          if (form.response[FILLING_20_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_20_20_MATERIAL], form.response[FILLING_20_20_AMOUNT], form.response[FILLING_20_20_SIZE]]);
          }
          if (form.response[FILLING_40_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_40_20_MATERIAL], form.response[FILLING_40_20_AMOUNT], form.response[FILLING_40_20_SIZE]]);
          }
          if (form.response[FILLING_80_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_80_20_MATERIAL], form.response[FILLING_80_20_AMOUNT], form.response[FILLING_80_20_SIZE]]);
          }
          if (form.response[FILLING_100_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_100_20_MATERIAL], form.response[FILLING_100_20_AMOUNT], form.response[FILLING_100_20_SIZE]]);
          }
          if (form.response[FILLING_LONG_EXISTING]) {
            materialsArray.push([form.response[FILLING_LONG_MATERIAL], form.response[FILLING_LONG_AMOUNT], form.response[FILLING_LONG_SIZE]]);
          }
          if (form.response[FILLING_FORTIFICATION_MATERIAL]) {
            materialsArray.push([form.response[FILLING_FORTIFICATION_MATERIAL], form.response[FILLING_FORTIFICATION_AMOUNT], form.response[FILLING_FORTIFICATION_SIZE]]);
          }
        } if (form.model === MODEL_PP002DIVERSO2 || form.model === MODEL_PP002DIVERSO3) {
          materialsArray.push(['Kątownik 30x20x3', form.response[BASE_BRACKET_AMOUNT], form.response[BASE_BRACKET_SIZE]]);
          materialsArray.push(['Kątownik 32x18x1', form.response[COVERING_BRACKET_AMOUNT], form.response[COVERING_BRACKET_SIZE]]);
          if (form.response[FILLING_20_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_20_20_MATERIAL], form.response[FILLING_20_20_AMOUNT], form.response[FILLING_20_20_SIZE]]);
          }
          if (form.response[FILLING_40_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_40_20_MATERIAL], form.response[FILLING_40_20_AMOUNT], form.response[FILLING_40_20_SIZE]]);
          }
          if (form.response[FILLING_80_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_80_20_MATERIAL], form.response[FILLING_80_20_AMOUNT], form.response[FILLING_80_20_SIZE]]);
          }
          if (form.response[FILLING_100_20_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[FILLING_100_20_MATERIAL], form.response[FILLING_100_20_AMOUNT], form.response[FILLING_100_20_SIZE]]);
          }
          if (form.response[FILLING_LONG_EXISTING]) {
            materialsArray.push([form.response[FILLING_LONG_MATERIAL], form.response[FILLING_LONG_AMOUNT], form.response[FILLING_LONG_SIZE]]);
          }
          if (form.response[FILLING_FORTIFICATION_MATERIAL]) {
            materialsArray.push([form.response[FILLING_FORTIFICATION_MATERIAL], form.response[FILLING_FORTIFICATION_AMOUNT], form.response[FILLING_FORTIFICATION_SIZE]]);
          }
        } if (form.model === MODEL_PP002P) {
          materialsArray.push(['Kształtownik 60x40x1,5', form.response[VERTICAL_FRAME_AMOUNT], form.response[VERTICAL_FRAME_SIZE]]);
          if (form.response[ADDITIONAL_HORIZONTAL_FRAME_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[ADDITIONAL_HORIZONTAL_FRAME_MATERIAL], form.response[ADDITIONAL_HORIZONTAL_FRAME_AMOUNT], form.response[ADDITIONAL_HORIZONTAL_FRAME_SIZE]]);
          }
          materialsArray.push(['Kształtownik 60x40x1,5', form.response[FRAME_WIDTH_HORIZONTAL_AMOUNT], form.response[FRAME_WIDTH_HORIZONTAL_SIZE]]);
          materialsArray.push(['Kształtownik 25x25x1,2', form.response[FILLING_AMOUNT], form.response[FILLING_SIZE]]);
        } if (form.model === MODEL_PP002P64) {
          materialsArray.push(['Kształtownik 60x50x1,5', form.response[VERTICAL_FRAME_AMOUNT], form.response[VERTICAL_FRAME_SIZE]]);
          if (form.response[ADDITIONAL_VERTICAL_FRAME_MATERIAL] !== NOT_APPLICABLE) {
            materialsArray.push([form.response[ADDITIONAL_VERTICAL_FRAME_MATERIAL], form.response[ADDITIONAL_VERTICAL_FRAME_AMOUNT], form.response[ADDITIONAL_VERTICAL_FRAME_SIZE]]);
          }
          materialsArray.push(['Kształtownik 60x50x1,5', form.response[FRAME_WIDTH_HORIZONTAL_AMOUNT], form.response[FRAME_WIDTH_HORIZONTAL_SIZE]]);
          materialsArray.push(['Kształtownik 60x40x1,25', form.response[FILLING_AMOUNT], form.response[FILLING_SIZE]]);
        } if (form.model === MODEL_PS005) {
          materialsArray.push(['Kształtownik 20x20x1,5', form.response[VERTICAL_MODULE_AMOUNT], form.response[VERTICAL_MODULE_SIZE]]);
          materialsArray.push(['Kształtownik 20x20x1,5', form.response[MODULE_WIDTH_AMOUNT], form.response[MODULE_WIDTH_SIZE]]);
          materialsArray.push([form.response[ADDITIONAL_VERTICAL_FRAME_MATERIAL], form.response[ADDITIONAL_VERTICAL_MODULE_AMOUNT], form.response[ADDITIONAL_VERTICAL_MODULE_SIZE]]);
          materialsArray.push(['Blacha 0,5x112mm DX51D', form.response[FILLING_AMOUNT], form.response[FILLING_SIZE]]);
        }
      }
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(materialsArray);
    workbook.SheetNames.push('Materiały');
    workbook.Sheets['Materiały'] = worksheet;
    XLSX.writeFile(workbook, `materialy-${orderNumber}.xlsx`);

    setProcessing(false);
  };

  const Button = ({ print }) => {
    const text = print ? 'Drukuj PDF' : 'Pobierz PDF';
    const icon = print ? faPrint : faFilePdf;

    return (
      <button
        className="btn"
        id="pdfBtn"
        type="button"
        onClick={() => generatePdf(print)}
        disabled={processing}
      >
        {processing && <FontAwesomeIcon className="spinner" icon={faSpinner} size="3x" />}
        {!processing && <FontAwesomeIcon icon={icon} size="3x" />}
        <span>{text}</span>
        <small className="form-text text-muted error">{error}</small>
      </button>
    );
  };
  const ButtonXls = () => (
    <button
      className="btn"
      id="pdfBtn"
      type="button"
      onClick={() => generateXls()}
      disabled={processing}
    >
      {processing && <FontAwesomeIcon className="spinner" icon={faSpinner} size="3x" />}
      {!processing && <FontAwesomeIcon icon={faFileExcel} size="3x" />}
      <span>Pobierz excela</span>
    </button>
  );

  const ButtonNewView = () => {
    if (!orderId) {
      return (
        <></>
      );
    }

    return (
      <button
        className="btn"
        id="pdfBtn"
        type="button"
        onClick={() => {
          history.push(`/orders/${orderId}/production-materials`);
        }}
        disabled={processing}
      >
        {processing && <FontAwesomeIcon className="spinner" icon={faSpinner} size="3x" />}
        {!processing && <FontAwesomeIcon icon={faWindowMaximize} size="3x" />}
        <span>Nowy widok</span>
        <small className="form-text text-muted error">{error}</small>
      </button>
    );
  };

  return (
    <section id="pdfButtonSection">
      <div id="pdfBtnContainer">
        <Button print={false} />
        <Button print />
        <ButtonXls />
        <ButtonNewView />
      </div>
    </section>
  );
};

PdfBtn.propTypes = {
  forms: PropTypes.array.isRequired,
  orderNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default PdfBtn;
