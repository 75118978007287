import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Modal, Box } from '@mui/material';
import { isUserAllowedForMaterialCard } from 'auth';

const Welcome = ({ setTest }) => {
  const [orderNumber, setOrderNumber] = useState(null);
  const [zamowieniaId, setZamowieniaId] = useState(null);
  const [calculationReady, setcalculationReady] = useState(false);
  const [materials, setMaterials] = useState(false);
  const [url, setUrl] = useState(null);
  const [active, setActive] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // tutaj po kliknieciu ok przenosi na link do pdfa
  const Popup = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <b>Dla tego zamówienia istnieje plik PDF na dysku siecowym.</b>
        <div className="popup-buttons">
          <a href={url} target="_blank" rel="noreferrer">Wyświetl</a>
        </div>
      </Box>
    </Modal>
  );

  const handleChange = async (e) => {
    const number = e.target.value;
    if (number.length >= 5) {
      try {
        const response = await axios.get(`/production-materials-manually-calculated/${number}`);
        if (response.status === 200 && response.data.products.length > 0) {
          sessionStorage.setItem('zamowienieActive', response.data.products[0].active);
          const activeZamowienie = response.data.products[0].active === 1;
          setActive(activeZamowienie);
          setMaterials(true);
          if (response.data.products[0].header !== '') {
            setcalculationReady(true);
          } else if (response.data.products[0]?.url !== '') {
            setUrl(response.data.products[0].url);
            setOpen(true);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      setZamowieniaId(number);
      sessionStorage.setItem('zamowieniaId', number);
    } else {
      setcalculationReady(false);
    }
    setOrderNumber(number);
  };

  return (
    <section>
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <form id="gettingStartedForm">
              <div className="container-xl">
                <div className="column">
                  {isUserAllowedForMaterialCard() && (
                    <>
                      <div className="row" id="gettingStartedFormLeftColUp">
                        <div className="form-group">
                          <Link to="/material-card">
                            <button
                              className="btn btn-info"
                              id="gettingStartedBtn"
                              type="button"
                              tabIndex={-1}
                              onClick={() => setTest(true)}
                            >
                              Wygeneruj kartę TESTOWĄ
                            </button>
                          </Link>
                        </div>
                        <div className="form-group">
                          <Link to="/material-card">
                            <button
                              className="btn btn-danger"
                              id="gettingStartedBtn"
                              type="button"
                              tabIndex={-1}
                              onClick={() => setTest(false)}
                            >
                              Wygeneruj kartę PRODUKCYJNĄ
                            </button>
                          </Link>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <label htmlFor="orderNumber">Wpisz numer zlecenia aby wczytać zamówienie</label>
                  <div className="row" id="gettingStartedFormLeftCol">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Numer zlecenia"
                        id="orderNumber"
                        name="width"
                        autoComplete="off"
                        value={orderNumber || ''}
                        onChange={handleChange}
                        autoFocus
                        minLength={5}
                        required
                      />
                    </div>
                    {calculationReady
                      ? (
                        <>
                          <Link to={`/production-materials-manually-calculated/${zamowieniaId}/show`}>
                            <button
                              className="btn btn-danger"
                              id="gettingStartedBtn"
                              type="button"
                              tabIndex={-1}
                            >
                              Pokaż kalkulację
                            </button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Popup />
                        </>
                      )}
                    {materials && !url && active
                      ? (
                        <>
                          <Link to={`/materials/${zamowieniaId}/production-materials`}>
                            <button
                              className="btn btn-primary"
                              id="gettingStartedBtn"
                              type="button"
                              tabIndex={-1}
                            >
                              Karta w linku z przyciskami
                            </button>
                          </Link>
                        </>
                      ) : (
                        <>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
