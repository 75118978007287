import React from 'react';

const AutomateBase = ({
  formId, onChange, value,
}) => (
  <div className="form-group">
    <label htmlFor={`automateBase__${formId}`}>Przygotowanie pod automat</label>
    <select
      className="form-control"
      id={`automateBase__${formId}`}
      name="automateBase"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value="yes">TAK</option>
      <option value="no">NIE</option>
    </select>
  </div>
);

export default AutomateBase;
