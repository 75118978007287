import React from 'react';

export const HINGES_SCREWED = 'screwed';
export const HINGES_GLUED = 'glued';
export const HINGES_180_SCREWED = '180screwed';
export const HINGES_180_GLUED = '180glued';
export const HINGES_AUTO = 'auto';

export const hingesInPolish = {
  [HINGES_SCREWED]: 'Przykręcany',
  [HINGES_GLUED]: 'Wklejany',
  [HINGES_180_SCREWED]: '180 stopni przykręcany',
  [HINGES_180_GLUED]: '180 stopni wklejany',
  [HINGES_AUTO]: 'Samodomykacz',
};

const HingesAccessories = ({
  formId, onChange, value, isTilting,
}) => (
  <div className="hingesInput">
    {isTilting && (
      <div className="form-group">
        <label htmlFor={`hinges__${formId}`}>Zawiasy</label>
        <select
          className="form-control"
          id={`hinges__${formId}`}
          name="hinges"
          onChange={onChange}
          value={value}
          required
        >
          <option value="" disabled>Wybierz</option>
          <option value={HINGES_SCREWED}>Przykręcane</option>
          <option value={HINGES_GLUED}>Wklejane</option>
          <option value={HINGES_180_SCREWED}>180 stopni przykręcane</option>
          <option value={HINGES_180_GLUED}>180 stopni wklejane</option>
        </select>
      </div>
    )}
    {!isTilting && (
      <div className="form-group">
        <label htmlFor={`hinges__${formId}`}>Zawiasy</label>
        <select
          className="form-control"
          id={`hinges__${formId}`}
          name="hinges"
          onChange={onChange}
          value={value}
          required
        >
          <option value="" disabled>Wybierz</option>
          <option value={HINGES_SCREWED}>Przykręcane</option>
          <option value={HINGES_GLUED}>Wklejane</option>
          <option value={HINGES_180_SCREWED}>180 stopni przykręcane</option>
          <option value={HINGES_180_GLUED}>180 stopni wklejane</option>
          <option value={HINGES_AUTO}>Samodomykacz</option>
        </select>
      </div>
    )}
  </div>
);

export default HingesAccessories;
