/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

const SingleLeafGateResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      {response.productionMaterials?.gallantry && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">GALANTERIA</th>
                  <th>{' '}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.gallantry).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.gallantry[key].name}</td>
                <td>{response.productionMaterials.gallantry[key].material}</td>
                <td>
                  {response.productionMaterials.gallantry[key].amount.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.gallantry[key].size.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].size.unit}
                </td>
                {isPdf && (
                  <>
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                    <td />
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isPdf && values.height < 1000 && (
        <div className="fillingPart">
          <p className="title">DESKA</p>
          <div className="fillingLine" />
          <div>
            <p>
              Ilość pól:
              {' '}
              {response.productionMaterials.info.areaAmount.value}
              {' '}
              {response.productionMaterials.info.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              Wysokość pola [H]:
              {' '}
              {response.productionMaterials.info.areaHeight.value}
              {' '}
              {response.productionMaterials.info.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              Szerokość pola [L]:
              {' '}
              {response.productionMaterials.info.areaWidth.value}
              {' '}
              {response.productionMaterials.info.areaWidth.unit}
            </p>
          </div>
          <div className="illustration">
            <div className="illustrationSquareGate" />
            <p className="illustrationHeightSymbolGate">H</p>
            <p className="illustrationWidthSymbolGate">L</p>
          </div>
        </div>
      )}
      {isPdf && values.height >= 1000 && values.width < 3000 && (
        <div className="fillingPart">
          <p className="title">DESKA</p>
          <div className="fillingLine" />
          <div>
            <p>
              Ilość pól nad automatem:
              {' '}
              {response.productionMaterials.infoAboveMachine.areaAmount.value}
              {' '}
              {response.productionMaterials.infoAboveMachine.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              Wysokość pola [H]:
              {' '}
              {response.productionMaterials.infoAboveMachine.areaHeight.value}
              {' '}
              {response.productionMaterials.infoAboveMachine.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              Szerokość pola [L]:
              {' '}
              {response.productionMaterials.infoAboveMachine.areaWidth.value}
              {' '}
              {response.productionMaterials.infoAboveMachine.areaWidth.unit}
            </p>
          </div>
          <div className="illustration">
            <div className="illustrationSquareGate" />
            <p className="illustrationHeightSymbolGate">H</p>
            <p className="illustrationWidthSymbolGate">L</p>
          </div>
          <div>
            <p>
              Ilość pól pod automatem:
              {' '}
              {response.productionMaterials.infoUnderMachine.areaAmount.value}
              {' '}
              {response.productionMaterials.infoUnderMachine.areaAmount.unit}
            </p>
          </div>
          <div>
            <p>
              Wysokość pola [H]:
              {' '}
              {response.productionMaterials.infoUnderMachine.areaHeight.value}
              {' '}
              {response.productionMaterials.infoUnderMachine.areaHeight.unit}
            </p>
          </div>
          <div>
            <p>
              Szerokość pola [L]:
              {' '}
              {response.productionMaterials.infoUnderMachine.areaWidth.value}
              {' '}
              {response.productionMaterials.infoUnderMachine.areaWidth.unit}
            </p>
          </div>
          <div className="illustration">
            <div className="illustrationSquareGate" />
            <p className="illustrationHeightSymbolGate">H</p>
            <p className="illustrationWidthSymbolGate">L</p>
          </div>
        </div>
      )}
    </div>
  );
};

SingleLeafGateResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default SingleLeafGateResponse;
