import React from 'react';

const Bolt = ({
  formId, onChange, value,
}) => (
  <div className="form-group">
    <label htmlFor={`bolt__${formId}`}>Rygiel</label>
    <select
      className="form-control"
      id={`bolt__${formId}`}
      name="bolt"
      value={value || ''}
      onChange={onChange}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value="0">0 szt</option>
      <option value="1">1 szt</option>
      <option value="2">2 szt</option>
    </select>
  </div>
);

export default Bolt;
